import Vue, { ref } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/store'
import axios from 'axios'
import router from './router'

Vue.config.productionTip = false


Vue.prototype.$axios = axios;

export const isLoading = ref(false);

axios.interceptors.request.use(
  config =>{
    isLoading.value=true;
    return config;
  },
  error =>{
    isLoading.value=false;
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response =>{
    isLoading.value=false;
    return response;
  },
  error =>{
    isLoading.value=false;
    return Promise.reject(error);
  }
)

new Vue({
  router,
  vuetify,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
