<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      hide-overlay
      absolute
      height="100%"
      mobile-breakpoint=""
    >
      <v-container fluid>
          <v-col col="12" class="pa-0">
            <div style="width:30%;margin-right:15px; margin-left:38px;  margin-top:30px;float: left; display:contents; ">
              <iframe style="min-width: 220px;" width="100%" height="210" src="https://www.youtube.com/embed/XrpjJBbNigc?si=qWuJtNWHyJvX7Bv2" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
            </div>
          </v-col>
      </v-container>

      <v-divider></v-divider>

      <v-container fluid >
        <v-col col="12" class="pa-0">
          <v-file-input
            accept=".json"
            v-model="selectedFile"
            :label="!howToUse? 'JSON File input':'test1.json (SBOM File)'"
            messages="Upload the SBOM file in JSON format"
            @change="selectFile"
            ref="fileInput"
            @keydown.enter.prevent="handleEnterKey"
          >
        </v-file-input>
          <v-btn
            style="height: 30px; font-size: 13px; position: absolute; right: 12px; margin-top: 7px;"
            color="blue-grey lighten-4"
            @click="submit()"
          >
          Scan
          </v-btn>
        </v-col>
      </v-container>

      <v-container style="margin-top: 30px; padding-bottom: 0px !important; text-align: right;">
          <v-btn @click="introduction()" text class="text-decoration-underline text-subtitle-1 text-right">INTRODUCTION</v-btn>
          <v-btn @click="howPage()" text class="text-decoration-underline text-subtitle-1 text-right">HOW TO USE</v-btn>
      </v-container>


      
      <div style="position: absolute; bottom: 0px; width: 100%;">
        <v-divider></v-divider>
        <v-card-title 
        style="min-height: 40px !important; padding: 0px !important;justify-content: center !important; margin-top: 10px;"
        >
          <a 
          v-for="(icon,index) in icons"
          :href="icon.link"
          v-bind:key="index"
          target="_blank"
          style="width: 50px; display: flex; text-decoration: none; justify-content: center; align-items: center;"
          class="snsBtn"
          >
            <v-hover v-slot="{hover}">
              <v-btn
              class="mx-4"
              icon
              :color="hover?icon.color:'rgba(0,0,0,.54)'"
              >
                <v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-hover>
          </a>
        </v-card-title>
        <div class="pa-0 text-center">info@wikisecurity.net</div>
        <div class="pa-1 text-center text-caption">Don't Miss To Follow Us On Our Social Networks Official Accounts.</div>
      </div>
    </v-navigation-drawer>
    
    <v-app-bar
      app
      color="blue-grey lighten-4"
      dense
      elevation="3"
      light
      id="main-bar"
    >
      <v-img
          class="mb-1"
          max-width="69"
          contain
          src="./assets/logo.png"
        >
      </v-img>
      <div class="px-3" style="font-size: 20px;">Sbom Vulnerability Scanner</div>
    </v-app-bar>
    <v-main>
      <default-page v-if="defaultPage"></default-page>
      <how-to-use :fileName="fileName" :jsonFile="propJson" :isNotSbom="isNotSbom" :firstUpload="firstUpload" v-if="howToUse"></how-to-use>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
// import { isLoading } from './main';
import firstJson from "@/assets/json/test1.json"
import DefaultPage from '@/views/defaultPage.vue'
import HowToUse from '@/views/howToUse.vue'

export default {
  name: 'App',
  components: {
    DefaultPage,
    HowToUse
  },
  data:()=>({
    fileName:'',
    selectedFile:null,
    jsonData:null,
    propJson:firstJson['packages'],
    defaultPage:true,
    howToUse:false,
    isNotSbom:false,
    firstUpload:false,
    icons: [
      {
       icon:'mdi-facebook',
       link:'https://www.facebook.com/people/Wiki-Security-INC/100064205460242/',
       color:'indigo' 
      },
      {
       icon:'mdi-youtube',
       link:'https://www.youtube.com/channel/UCddHTMigvEFbI8Zu29ODwAQ?view_as=subscriber',
       color:'red' 
      },
      {
       icon:'mdi-linkedin',
       link:'https://in.linkedin.com/company/wiki-security',
       color:'blue' 
      }
    ],
  }),
  created(){
    console.log(firstJson)
    this.propJson=firstJson['packages'];
  },
  methods:{
    reset:function(value){
      this.howToUse=value;
      this.selectedFile=null;
      this.isNotSbom=false;
    },
    introduction:function(){
      this.defaultPage=true;
      this.howToUse=false;
    },
    howPage:function(){
      this.selectedFile=null;
      this.defaultPage=false;
      this.howToUse=true;
      this.propJson=firstJson['packages'];
      this.firstUpload=false;
    },
    selectFile:function(file){
      this.fileName=file.name;
      this.isNotSbom=false;
      this.jsonData=file;
      console.log(this.jsonData);
    },
    handleEnterKey(event) {
      event.preventDefault();
      this.$refs.fileInput.blur();
      this.submit();
    },
    submit:function(){
          var formData = new FormData();
          formData.append('jsonFile',this.jsonData); 
          this.defaultPage=false;
          this.howToUse=true;
      
          axios.post("https://vul-api.wikisecurity.net/sbom/upload", formData).then((res)=>{
            this.firstUpload=true;
            console.log(res);
            var packages=res.data.packages;
            if(packages==undefined){
              console.log("undefined")
              this.isNotSbom=true;
              this.firstUpload=false;
              this.howToUse=true;
              this.selectedFile=null;
              this.propJson=firstJson['packages'];
            }else{
              this.isNotSbom=false;
              // this.firstUpload=true;
              this.propJson=packages;
            }
    
          }).catch((error)=>{
            console.log(error);
          })
 
    },
  }
}
</script>

<style>
.v-toolbar__content{
  height: 48px;
}
#main-bar{
  left: 0 !important;
}
.v-overlay__content{
  position: absolute !important;
  top: calc(100vh/2);
}
.v-btn:not(.v-btn--round).v-size--default::before{
  background-color: transparent !important;
}
</style>
