<template>
  <div style="height: 100%">
    <v-container fluid style="height: 100%">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <div
              style="
                display: flex;
                justify-content: left;
                align-items: baseline;
              "
            >
              <v-card-title
                style="width: max-content; padding-bottom: 0px !important"
              >
                1. Roles of Vendor and User in S/W Supply Chain Security
              </v-card-title>
            </div>

            <v-card-text class="text-block" style="position: relative">
              <div
                class="text-container"
                @mouseover="hoveringPart1 = true"
                @mouseout="hoveringPart1 = false"
                :class="{ active: hoveringPart1 }"
              >
                <div
                  class="text-content"
                  v-if="(!hoveringPart1 && !part1) || (hoveringPart1 && part1)"
                >
                  In the S/W Supply Chain, a management system that divides the
                  roles of S/W Vendors and Users is essential for managing the
                  security and compliance of open source software. The S/W
                  Vendor is responsible for generating the SBOM (Software Bill
                  of Materials) for the open source components used in the
                  developed software, addressing license issues and eliminating
                  security vulnerabilities before distribution. The S/W User, on
                  the other hand, is responsible for regularly checking the SBOM
                  received from the Vendor for newly disclosed security
                  vulnerabilities and license issues to ensure compliance.
                </div>
                <div
                  class="text-content"
                  v-if="(hoveringPart1 && !part1) || (!hoveringPart1 && part1)"
                >
                  S/W Supply Chain에서 Open Source의 보안과 컴플라이언스 관리를
                  위해 S/W Vendor와 User는 그 역할이 분담되는 관리체계를
                  고려해야 한다. S/W Vendor는 개발된 S/W에 사용된 Open source의
                  내용을 SBOM 규격으로 생성하고 라이선스 문제와 보안 취약점을
                  제거하여 배포하는 역할을 담당한다. S/W User는 Vendor에게 받은
                  SBOM을 활용하여 신규로 알려지는 보안 취약점과 라이선스 문제를
                  정기적으로 체크하여 관련 Compliance를 대응하는 역할을
                  담당한다.
                </div>
              </div>
            </v-card-text>

            <v-card-text class="text-block_2" style="position: relative">
              <div
                class="text-container"
                @mouseover="hoveringPart2 = true"
                @mouseout="hoveringPart2 = false"
                :class="{ active: hoveringPart2 }"
              >
                <div
                  class="text-content"
                  v-if="(!hoveringPart2 && !part2) || (hoveringPart2 && part2)"
                >
                  From the S/W User's perspective, it is inevitable that new
                  security vulnerabilities will be discovered during the receipt
                  or use of the software, even with the SBOM and security
                  vulnerability elimination results provided by the Vendor. This
                  is because the SBOM generation cycle of the S/W Vendor and the
                  disclosure cycle of security vulnerabilities are different.
                  Therefore, it is wise for the S/W User to regularly check and
                  assess the security vulnerabilities of the open source
                  components registered in the SBOM provided by the Vendor and
                  respond to compliance and threats accordingly.
                </div>
                <div
                  class="text-content"
                  v-if="(hoveringPart2 && !part2) || (!hoveringPart2 && part2)"
                >
                  S/W User의 입장에서, Vendor에게 제공받은 SBOM과 보안 취약점
                  제거 결과는 SW를 수령하는 도중이나 사용하는 도중에 보안
                  취약점이 새롭게 알려지는 상황이 될수 밖에 없다. 즉, S/W
                  Vendor의 SBOM생성 주기와 보안 취약점이 알려지는 주기가 다르기
                  때문에 S/W User는 Vendor가 제공하는 SBOM에 등록된 Open
                  Source에 대한 보안취약점을 정기적으로 체크하고 위험평가하여
                  Compliance와 Threat에 대응하는 것이 현명한 방법이다.
                </div>
              </div>
            </v-card-text>
            <v-card-text
              class="image-block"
              style="
                display: flex;
                justify-content: center;
                height: 250px;
                margin-top: -25px;
                margin-bottom: 10px;
              "
            >
              <img src="@/assets/roles.png" class="image-1" />
            </v-card-text>

            <div
              style="
                display: flex;
                justify-content: left;
                align-items: baseline;
              "
            >
              <v-card-title
                style="width: max-content; padding-bottom: 0px !important"
              >
                2. Cost-effective Strategy for S/W User
              </v-card-title>
            </div>

            <v-card-text class="text-block" style="position: relative">
              <div
                class="text-container"
                @mouseover="hoveringPart3 = true"
                @mouseout="hoveringPart3 = false"
                :class="{ active: hoveringPart3 }"
              >
                <div
                  class="text-content"
                  v-if="(!hoveringPart3 && !part3) || (hoveringPart3 && part3)"
                >
                  One of the cost-effective strategies for S/W Users in securing
                  the S/W Supply Chain is integrating the SBOM Vulnerability
                  Scanner with the organization's SCM (Software Configuration
                  Management) system to address S/W Supply Chain security
                  compliance and threats. In other words, by regularly scanning
                  the SBOM received from the S/W Vendor for security
                  vulnerabilities, monitoring, and requesting the removal of
                  vulnerabilities, the SBOM Vulnerability Scan engine is
                  utilized. The organization uses its SCM system to maintain and
                  manage the configuration of the SBOM files and vulnerability
                  scan result files provided by the vendor.
                </div>
                <div
                  class="text-content"
                  v-if="(hoveringPart3 && !part3) || (!hoveringPart3 && part3)"
                >
                  S/W User가 S/W Supply Chain보안에 대한 비용 효과적인 전략 중에
                  하나는 SBOM Vulnerability Scanner와 조직이 운영하고 이는
                  SCM(Software Configuration Management) 시스템을 연동하여 S/W
                  Supply Chain보안 Compliance와 Threat에 대응하는 것이다. 즉,
                  S/W Vendor에게 받은 SBOM을 정기적으로 보안 취약점 스캔을 하여
                  모니터링 및 취약점 제거 요청을 하기 위한 SBOM Vulnerability
                  Scan 엔진을 사용하고, 조직에서 사용하는 SCM시스템을 이용하여
                  제조사가 제공한 SBOM 파일과 취약점 스캔결과파일에 대한 형상을
                  유지관리하는 것이다.
                </div>
              </div>
            </v-card-text>

            <v-card-text class="text-block_4" style="position: relative">
              <div
                style="height: 40px"
                class="text-container"
                @mouseover="hoveringPart4 = true"
                @mouseout="hoveringPart4 = false"
                :class="{ active: hoveringPart4 }"
              >
                <div
                  class="text-content"
                  v-if="(!hoveringPart4 && !part4) || (hoveringPart4 && part4)"
                >
                  This website offers an SBOM Vulnerability Scan engine that
                  anyone can use. It allows users to upload the SBOM provided by
                  the manufacturer to scan for known security vulnerabilities in
                  the open source components listed in the SBOM and generate the
                  results in CSV (or JSON) format for integration with the
                  organization's SCM system.
                </div>
                <div
                  class="text-content"
                  v-if="(hoveringPart4 && !part4) || (!hoveringPart4 && part4)"
                >
                  이 웹사이트는 누구나가 사용할 수 있는 SBOM Vulnerability Scan
                  엔진이며, 제조사가 제공한 SBOM을 업로드하여 SBOM에 등록된 Open
                  Source에 알려진 보안 취약점을 스캔하고 그 결과를 조직의 SCM
                  시스템과 연동할 수 있도록 CSV(또는 JSON) 포멧으로 생성하는
                  기능을 제공한다.
                </div>
              </div>
            </v-card-text>

            <v-card-text
              class="image-block"
              style="
                display: flex;
                justify-content: center;
                height: 350px;
                margin-top: -50px;
                margin-bottom: 5px;
              "
            >
              <img src="@/assets/costEffect.png" class="image-2" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    part1: false,
    part2: false,
    part3: false,
    part4: false,
    hoveringPart1: false,
    hoveringPart2: false,
    hoveringPart3: false,
    hoveringPart4: false,
  }),
};
</script>

<style>
.text-container {
  color: black;
  display: flex;
  position: relative;
  align-items: center;
  line-height: 1.8;
  padding: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 125px;
  margin-top: -25px;
}

.text-content {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .text-container {
    height: auto;
    padding: 10px;
    font-size: 16px;
    line-height: 1.6;
  }
  .v-card-text {
    margin-bottom: 3px;
  }
  img {
    width: 100%;
    height: auto;
  }
  .image-2{
    margin-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-container {
    height: auto;
    padding: 10px;
    font-size: 16px;
    line-height: 1.6;
  }
  .v-card-text {
    margin-bottom: 3px;
  }
  img {
    width: 100%;
    height: auto;
  }
  .image-2{
    margin-top: 40px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .image-block {
    margin-top: 20px;
  }
  .image-1,
  .image-2 {
    width: 400px;
    height: 150px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1570px){
  .text-block {
    margin-top: 0px;
  }
  .text-block_2 {
    margin-top: -23px;
  }
  .text-block_4 {
    margin-top: -42px;
  }
  .image-1 {
    width: 650px;
    height: 250px;
  }
  .image-2 {
    width: 650px;
    height: 320px;
  }
}

@media only screen and (min-width: 1580px) {
  .text-block_2 {
    margin-top: -54px;
  }
  .text-block_4 {
    margin-top: -60px;
  }
}
</style>
