import Vue from "vue";
import VueRouter from "vue-router";
// import DefaultPage from '@/views/home.vue';
// import HowToUse from '@/views/howToUse.vue';

Vue.use(VueRouter)

const routes=[
    // {
    //     path:'/',
    //     redirect: '/default'
    // },
    // {
    //     path:'/default',
    //     name:'DefaultPage',
    //     component: DefaultPage
    // },
    // {
    //     path:'/how',
    //     name:'HowToUse',
    //     component: HowToUse
    // }
    // {
    //     path: '/home',
    //     name:'DefaultPage',
    //     component: DefaultPage
    // }
]

const router = new VueRouter({
    routes
})

export default router