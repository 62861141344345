<template>
  <div style="height: 100%; width: 100%">
    <v-container fluid v-if="!firstUpload1">
      <v-fade-transition v-if="isNotSbom1">
        <v-overlay
          absolute="absolute"
          opacity="0.3"
          style="z-index: 2"
          @click="resetPage()"
        >
          <v-alert type="warning">
            It's not SBOM File. You have to upload SBOM JSON File. Read 'HOW TO
            USE' and try again.
          </v-alert>
        </v-overlay>
      </v-fade-transition>
      <v-card outlined style="padding: 10px">
        <div style="width: 100%; display: flex; align-items: center">
          <img
            src="@/assets/help-box.svg"
            style="height: 25px; min-width: 25px"
          />
          <div class="text-h6 font-weight-medium" style="margin-left: 5px">
            HOW TO USE
          </div>
        </div>
        <div>
          Step 1. Click the textbox to open a Windows Explorer window and select
          the SBOM file in JSON format.
        </div>
        <div>
          Step 2. Click the 'SUBMIT' button to submit the selected SBOM file.
        </div>
        <div>
          Step 3. View the security vulnerability scan results for the
          open-source packages in the submitted SBOM file.
        </div>
        <br />
        <div>
          * The database used to scan for security vulnerabilities in open
          source packages is updated daily to maintain up-to-dateness.
        </div>
        <div>
          * The screen below shows the security vulnerability scan results for a
          sample SBOM file.
        </div>
      </v-card>
    </v-container>

    <v-divider v-if="!firstUpload1"></v-divider>

    <v-container fluid style="padding: 0">
      <v-fade-transition v-if="isLoading">
        <v-overlay absolute="absolute" opacity="0.3" zIndex="2">
          <div style="width: 100%; height: 100%">
            <v-progress-circular
              :size="100"
              :width="13"
              color="orange lighten-2"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-overlay>
      </v-fade-transition>

      <div
        style="
          width: 100%;
          display: flex;
          justify-content: left;
          margin-top: 10px;
          align-items: center;
          border-bottom: 1px solid lightgray;
          box-sizing: border-box;
        "
        v-if="firstUpload1"
      >
        <v-btn icon @click="downloadCSV()">
          <img
            src="@/assets/tray-arrow-down.svg"
            style="height: 24px; width: 24px"
          />
        </v-btn>
        <div style="margin-left: 1px; padding: 10px">Download for CSV File</div>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">type</th>
              <th class="text-left">name</th>
              <th class="text-left">version</th>
              <th class="text-left">severity</th>
              <th class="text-left">vulnerabilitie</th>
              <th class="text-left">EPSS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in allData" v-bind:key="index">
              <td v-if="index == 0" :rowspan="typeRow">{{ data.type }}</td>
              <td
                v-if="index == 0 || data.name != allData[index - 1].name"
                :rowspan="data.nameRow"
              >
                {{ data.name }}
              </td>
              <td>{{ data.version }}</td>
              <td>{{ data.severity }}</td>
              <td>{{ data.vulnerability }}</td>
              <td>{{ data.epss }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
// import axios from 'axios';
import { isLoading } from "../main";
import firstJson from "@/assets/json/test1.json";

export default {
  props: {
    jsonFile: {
      required: true,
    },
    isNotSbom: Boolean,
    firstUpload: Boolean,
    fileName: String,
  },
  data: () => ({
    jsonFile1: null,
    allData: [],
    typeRow: 1,
    firstUpload1: false,
    isNotSbom1: false,
    isLoading,
  }),
  created() {
    console.log(this.jsonFile);
    this.jsonFile1 = this.jsonFile;
    var firstJsonFile = this.jsonFile1;
    this.arrangeFile(firstJsonFile);
    this.typeRow = this.allData.length;
  },
  watch: {
    jsonFile: function (file) {
      console.log(2);
      console.log(file);
      this.arrangeFile(file);
      this.typeRow = this.allData.length;
      console.log(this.allData);
    },
    firstUpload: {
      immediate: true,
      handler(value) {
        this.firstUpload1 = value;
      },
    },
    isNotSbom: {
      immediate: true,
      handler(value) {
        console.log(value);
        this.isNotSbom1 = value;
      },
    },
  },
  methods: {
    downloadCSV: function () {
      console.log(this.allData);
      var string = "Type, Name, Version, Severity, Vulnerability, EPSS\n";
      this.allData.forEach((e) => {
        console.log(e);
        string += e.type + ", ";
        string += e.name + ", ";
        string += e.version + ", ";
        string += e.severity + ", ";
        string += e.vulnerability.replace(/,/gi, ";") + ", ";
        string += e.epss + "\n";
      });
      console.log(string);
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(string);
      anchor.target = "_blank";
      const baseFileName = this.fileName.endsWith(".json")
        ? this.fileName.slice(0, -5)
        : this.fileName;

      anchor.download = baseFileName + ".csv";
      anchor.click();
    },
    resetPage: function () {
      var firstJsonFile = firstJson["packages"];
      this.arrangeFile(firstJsonFile);
      console.log("allData");
      console.log(this.allData);
      this.typeRow = this.allData.length;
      this.isNotSbom1 = false;
      this.firstUpload1 = false;
      this.$emit("reset", true);
    },
    arrangeFile: function (packages) {
      this.allData = [];
      packages.forEach((x) => {
        x.vulnerabilities.forEach((y) => {
          var pack = {};
          pack.nameRow = x.vulnerabilities.length;

          // type, name, version
          var coord = x.coordinates.split("/");
          pack.type = coord[0].substring(coord[0].indexOf(":") + 1);

          // 버전 존재여부 확인
          var isVersion = coord[coord.length - 1].indexOf("@");
          var name1 =
            isVersion == -1
              ? coord[coord.length - 1]
              : coord[coord.length - 1].substring(
                  coord[coord.length - 1].lastIndexOf("/") + 1,
                  isVersion
                );
          pack.name = name1.length == 0 ? "-" : name1;
          pack.version =
            isVersion == -1
              ? "-"
              : coord[coord.length - 1].substring(isVersion + 1);

          pack.severity = y.severity;
          pack.vulnerability = y.id;

          if (y.epss.percentile != undefined) {
            var percentile = Number(y.epss.percentile);
            pack.epss =
              Math.floor(percentile.toFixed(2) * 100).toString() + " %";
          } else {
            pack.epss = "N/A";
          }
          this.allData.push(pack);
        });
      });
    },
  },
};
</script>

<style></style>
